import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class OuterChain extends Vue {
  public url: String = '';
  public open(): void {
    window.open('');
  }
  public created(): void {
    console.log(this.$route);
  }
}
